var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.$t("permission.title.data", [
              _vm.entity !== null
                ? _vm.$t(`permission.${_vm.entity.toLowerCase()}`)
                : null,
            ]),
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
            "ok-title": _vm.$t("button.select"),
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  [
                    !_vm.readOnly &&
                    !_vm.departmentRuleApplied &&
                    !_vm.customerRuleApplied
                      ? _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "success" },
                            on: { click: _vm.ok },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        )
                      : _vm._e(),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            _vm.departmentRuleApplied || _vm.customerRuleApplied
                              ? "button.close"
                              : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: _vm.alertError ? "danger" : "success",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: {
                  icon: _vm.alertError
                    ? ["fas", "triangle-exclamation"]
                    : ["far", "check"],
                },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _vm.departmentRuleApplied
            ? _c("div", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("permission.departmentRule"))),
                ]),
              ])
            : _vm.customerRuleApplied
            ? _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.$t("permission.customerRule")))]),
              ])
            : _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.$t("permission.include") } },
                    [
                      _c("b-form-radio-group", {
                        attrs: {
                          id: "radio-group-1",
                          options: _vm.operators,
                          name: "operator",
                        },
                        on: { change: _vm.operatorChanged },
                        model: {
                          value: _vm.operator,
                          callback: function ($$v) {
                            _vm.operator = $$v
                          },
                          expression: "operator",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("label", [_vm._v(_vm._s(_vm.$t("permission.except")))]),
                  _c(
                    "b-btn",
                    {
                      staticClass: "d-inline-block btn-action",
                      attrs: { id: `BTN_CLEAR_SELECTED` },
                      on: {
                        click: function ($event) {
                          return _vm.clear()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "trash-can"] },
                      }),
                    ],
                    1
                  ),
                  _c("b-popover", {
                    attrs: {
                      target: `BTN_CLEAR_SELECTED`,
                      placement: "top",
                      boundary: "viewport",
                      triggers: "hover",
                      content: _vm.$t("button.clear_all"),
                    },
                  }),
                  _c(
                    "b-form-group",
                    _vm._l(_vm.editDataRules, function (rule, index) {
                      return _c(
                        "b-input-group",
                        { key: index },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "permission-value-dropdown",
                              class:
                                _vm.filterValuesText !== ""
                                  ? "notempty-permission-value-dropdown"
                                  : "",
                              attrs: { lazy: "", text: _vm.filterValuesText },
                            },
                            [
                              _vm.selectOptions.length !== 0
                                ? _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "filter_component.search"
                                      ),
                                    },
                                    model: {
                                      value: _vm.listFilter,
                                      callback: function ($$v) {
                                        _vm.listFilter = $$v
                                      },
                                      expression: "listFilter",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "b-dropdown-form",
                                [
                                  _c("b-form-checkbox-group", {
                                    attrs: {
                                      options: _vm.filteredSelectOptions,
                                      stacked: "",
                                    },
                                    on: { change: _vm.checkboxChanged },
                                    model: {
                                      value: _vm.selected,
                                      callback: function ($$v) {
                                        _vm.selected = $$v
                                      },
                                      expression: "selected",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }