var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "DATE_TIME" },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "sm",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "datetime-modal shadow",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.isOptional
                    ? _c(
                        "b-button",
                        {
                          staticClass: "button-float-left",
                          attrs: { size: "sm", variant: "secondary" },
                          on: { click: _vm.clear },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.clear")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.alertError,
                callback: function ($$v) {
                  _vm.alertError = $$v
                },
                expression: "alertError",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.$t("error.attention_required")) + " "),
            ],
            1
          ),
          _c(
            "b-form-group",
            { staticClass: "mb-0", attrs: { "label-for": "generic-date" } },
            [
              _c(
                "b-row",
                [
                  _vm.editMode != 2
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("b-form-datepicker", {
                            staticClass: "mb-2",
                            attrs: {
                              id: "generic-date",
                              "today-button": "",
                              "reset-button": _vm.isOptional,
                              "close-button": "",
                              "hide-header": "",
                              "label-today-button": _vm.$t("date.today"),
                              "label-reset-button": _vm.$t("date.reset"),
                              "label-close-button": _vm.$t("date.close"),
                              "today-button-variant": "primary",
                              "reset-button-variant": "danger",
                              "close-button-variant": "secondary",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function (ref) {
                                    return [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "calendar-days"],
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2621928167
                            ),
                            model: {
                              value: _vm.date,
                              callback: function ($$v) {
                                _vm.date = $$v
                              },
                              expression: "date",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editMode != 1
                    ? _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("Timepicker", {
                            model: {
                              value: _vm.time,
                              callback: function ($$v) {
                                _vm.time = $$v
                              },
                              expression: "time",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showDateTimeError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v(
                    "  " + _vm._s(_vm.errors.first("generic_datetime")) + " "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }